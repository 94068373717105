import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <h1>Feel free to contact me for any work or suggestions below </h1>
        <div className="container py-4">
          <form class="col-lg-6 offset-lg-3 ">
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input className="form-control" type="text" id="name" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input className="form-control" type="email" id="email" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea className="form-control" id="message" required />
            </div>
            <button className="btn btn-danger submit" type="submit" value="submit">
              {/* {formStatus} */}Submit
            </button>
          </form>
        </div>
        {/* <div className="row section-head">
                <div className="ten columns">
                  <p className="lead">
                    Feel free to contact me for any work or suggestions below
                  </p>
                </div>
              </div>
              <div className="row">
                <aside className="eigth columns footer-widgets">
                  <div className="widget">
                    <h4>Linked in :
                      {resumeData.linkedinId}
                    </h4>
                  </div>
                </aside>
              </div> */}
      </section>
    );
  }
}

// const ContactForm = () => {
//   const [formStatus, setFormstatus] = React.useState('Send')
//   const onSubmit = (e) => {
//     e.preventDefault()
//     setFormstatus('Submitting...')
//     const { name, email, message } = e.target.elements
//     let conFom = {
//       name: name.value,
//       email: email.value,
//       message: message.value,
//     }
//   }
//   return (
//     <div className="container mt-5">
//       <h2 className="mb-3">React Contact Form Component Example</h2>
//       <form onSubmit={onSubmit}>
//         <div className="mb-3">
//           <label className="form-label" htmlFor="name">
//             Name
//           </label>
//           <input className="form-control" type="text" id="name" required />
//         </div>
//         <div className="mb-3">
//           <label className="form-label" htmlFor="email">
//             Email
//           </label>
//           <input className="form-control" type="email" id="email" required />
//         </div>
//         <div className="mb-3">
//           <label className="form-label" htmlFor="message">
//             Message
//           </label>
//           <textarea className="form-control" id="message" required />
//         </div>
//         <button className="btn btn-danger" type="submit">
//           {formStatus}
//         </button>
//       </form>
//     </div>
//   )
// }

// export default ContactForm