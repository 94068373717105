let resumeData = {
    "imagebaseurl": "https://rbhatia46.github.io/",
    "name": "Aroopi",
    "role": "",
    "linkedinId": "Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I am Hari Sankar S.  I do Music analysis and create original composition. -in all its forms, style or genre. Music truly brings joy to my heart.I simply attempt to share that feeling with you.Music Education, but with passion.",
    "socialLinks": [
        {
            "name": "Youtube",
            "url": "https://www.youtube.com/@aroopi",
            "className": "fa-brands fa-youtube"
        },
        {
            "name": "Apple Music",
            "url": "https://music.apple.com/us/artist/aroopi/1651207506",
            "className": "fa-brands fa-apple"
        },
        {
            "name": "Spotify",
            "url": "https://open.spotify.com/user/31gb2e5ofhtfyjtqdgbxdbaj2j4u",
            "className": "fa-brands fa-spotify"
        }
    ],
    "aboutme": "I am currently a pre-final year student at The LNM Institute of Information Technology and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Machine Learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address": "India",
    "website": "https://rbhatia46.github.io",
    "education": [
        {
            "UniversityName": "The LNM Insitute of Information Technology",
            "specialization": "Some specialization",
            "MonthOfPassing": "Aug",
            "YearOfPassing": "2020",
            "Achievements": "Some Achievements"
        },
        {
            "UniversityName": "Some University",
            "specialization": "Some specialization",
            "MonthOfPassing": "Jan",
            "YearOfPassing": "2018",
            "Achievements": "Some Achievements"
        }
    ],
    "work": [
        {
            "CompanyName": "Some Company",
            "specialization": "Some specialization",
            "MonthOfLeaving": "Jan",
            "YearOfLeaving": "2018",
            "Achievements": "Some Achievements"
        },
        {
            "CompanyName": "Some Company",
            "specialization": "Some specialization",
            "MonthOfLeaving": "Jan",
            "YearOfLeaving": "2018",
            "Achievements": "Some Achievements"
        }
    ],
    "skillsDescription": "Your skills here",
    "skills": [
        {
            "skillname": "HTML5"
        },
        {
            "skillname": "CSS"
        },
        {
            "skillname": "Reactjs"
        }
    ],
    "portfolio": [
        {
            "name": "project1",
            "description": "mobileapp",
            "imgurl": "images/portfolio/phone.jpg"
        },
        {
            "name": "project2",
            "description": "mobileapp",
            "imgurl": "images/portfolio/project.jpg"
        },
        {
            "name": "project3",
            "description": "mobileapp",
            "imgurl": "images/portfolio/project2.png"
        },
        {
            "name": "project4",
            "description": "mobileapp",
            "imgurl": "images/portfolio/phone.jpg"
        }
    ],
    "testimonials": [
        {
            "description": "This is a sample testimonial",
            "name": "Some technical guy"
        },
        {
            "description": "This is a sample testimonial",
            "name": "Some technical guy"
        }
    ]
}

export default resumeData